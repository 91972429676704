import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				FAQ | Perguntas mais frequentes no Fit León Gym
			</title>
			<meta name={"description"} content={"No Fit León Gym, compreendemos que o início de um novo percurso de fitness pode vir acompanhado de muitas perguntas."} />
			<meta property={"og:title"} content={"FAQ | Perguntas mais frequentes no Fit León Gym"} />
			<meta property={"og:description"} content={"No Fit León Gym, compreendemos que o início de um novo percurso de fitness pode vir acompanhado de muitas perguntas."} />
			<meta property={"og:image"} content={"https://braidolets.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://braidolets.com/img/9718001.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://braidolets.com/img/9718001.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://braidolets.com/img/9718001.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://braidolets.com/img/9718001.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://braidolets.com/img/9718001.png"} />
			<meta name={"msapplication-TileImage"} content={"https://braidolets.com/img/9718001.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" quarkly-title="FAQ-8">
			<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--darkL1">
				FAQ
			</Text>
			<Text margin="0px 0px 70px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#555a5f" sm-margin="0px 0px 50px 0px">
				No Fit León Gym, compreendemos que o início de um novo percurso de fitness pode vir acompanhado de muitas perguntas. Aqui está uma compilação das perguntas mais comuns que recebemos, todas respondidas para o ajudar a começar com confiança.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						1. Qual é o horário do vosso ginásio?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						O nosso ginásio está aberto desde manhã cedo até tarde da noite, permitindo-lhe a flexibilidade de treinar quando lhe for mais conveniente.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						2. Preciso de ser um frequentador experiente de ginásio para me inscrever?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						De modo algum! O ginásio Fit León é adequado para todos os níveis de fitness, desde os principiantes até aos atletas mais experientes.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						3. Há treinadores disponíveis para me ajudarem com os meus treinos?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Sim, temos treinadores certificados disponíveis para o orientar. Eles podem ajudá-lo a familiarizar-se com o equipamento e garantir que está a fazer exercício de forma segura e eficaz.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						4. Posso experimentar uma aula antes de me tornar membro?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Oferecemos uma aula experimental única para os recém-chegados. É uma óptima maneira de experimentar a vibração da nossa comunidade de ginásio.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						5. O que devo trazer para a minha primeira visita?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Basta trazeres-te a ti próprio, uma garrafa de água e uma toalha. Se tenciona utilizar os balneários, um cadeado também pode ser útil.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						6. Existem cacifos para guardar os objectos pessoais?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Sim, dispomos de cacifos para sua conveniência. Pode trazer o seu próprio cadeado para guardar os seus objectos.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Contacts />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});